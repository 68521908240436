import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3';
import './../sass/app.scss';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import PrimeVue from 'primevue/config';
import Aura from '@primeuix/themes/aura';
import vClickOutside from "click-outside-vue3"
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { PromiseDialog as VuejsDialog } from './libs/vue-dialog';
import './libs/vue-dialog/styles/main.scss';

const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || 'Console';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    ),
  // resolve: (name) => {
  //   const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
  //   return pages[`./Pages/${name}.vue`];
  // },
  setup({ el, App, props, plugin }) {

    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(VuejsDialog)
      .use(vClickOutside)
      .use(PrimeVue, {
        theme: {
          preset: Aura
        }
      });

    app.config.globalProperties.$storage = window.storage
    app.config.globalProperties.$route = route

    app.mount(el)

  },
  progress: {
    color: '#4B5563'
  }
});
